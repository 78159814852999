<template>
<div>
  <vs-row vs-justify="center">
    <vs-col type="flex" vs-justify="center" vs-align="center" vs-lg="12" vs-xs="12">
      <vs-card>
        <h2 class="card-title d-flex">{{$t('ORDER.HEADER.TITEL')}}
          <div class="ml-auto text-right">
            <vs-button  @click="LoadData()" size="small" color="primary" type="filled" icon="refresh"></vs-button>
          </div>
        </h2>
        <p class="card-subtitle">
          <span>{{$t('ORDER.HEADER.TEXT')}}</span>
        </p>
      </vs-card>
    </vs-col>
  </vs-row>

  <vs-row vs-justify="center">
    <vs-col type="flex" vs-justify="center" vs-align="center" vs-lg="12" vs-sm="12" vs-xs="12">
      <vs-card >
            <div id="orders_table" class="vs-con-loading__container" >
              <vs-table
                search
                :data="orders"
                :noDataText="$t('ORDER.NO_DATA')"
                stripe
                max-items="25" 
                pagination
                hoverFlat>

                <template slot="header">
                  <h3>
                    {{$t('ORDER.TABLE.HEADER')}}
                  </h3>
                </template>
                <template slot="thead">                               
                  <vs-th sort-key="tr.order.bez">
                    {{$t('ORDER.OVERVIEWTABLE.COL1')}}
                  </vs-th>
                  <vs-th sort-key="tr.order.order_nr">
                    {{$t('ORDER.OVERVIEWTABLE.COL2')}}
                  </vs-th>
                  <vs-th sort-key="tr.debitorName">
                    {{$t('ORDER.OVERVIEWTABLE.COL3')}}
                  </vs-th> 
                  <vs-th sort-key="tr.order.offerbez">
                    {{$t('ORDER.OVERVIEWTABLE.COL4')}}
                  </vs-th>    
                  <vs-th sort-key="tr.order.last_change">
                    {{$t('ORDER.OVERVIEWTABLE.COL5')}}
                  </vs-th> 
                  <vs-th sort-key="tr.order.state">
                    {{$t('ORDER.OVERVIEWTABLE.COL6')}}
                  </vs-th>  
                  <vs-th sort-key="tr.order.created_by_str">
                    {{$t('ORDER.OVERVIEWTABLE.COL7')}}
                  </vs-th> 
                  <vs-th>
                    {{$t('ORDER.OVERVIEWTABLE.COL8')}}
                  </vs-th> 
                  <vs-th>
                    {{$t('ORDER.OVERVIEWTABLE.COL9')}}
                  </vs-th>                                                                                                                                                                                                                              
                  <vs-th></vs-th>                                                                                                                                             
                </template>

                <template slot-scope="{data}">
                  <vs-tr :data="tr"  :key="indextr" v-for="(tr, indextr) in data" >                   
                    <vs-td :data="tr.order.bez">
                      {{tr.order.bez}}
                    </vs-td>
                    <vs-td :data="tr.order.order_nr">
                      {{tr.order.order_nr}}
                    </vs-td>    
                    <vs-td :data="tr.debitorName">
                      {{tr.debitorName}}
                    </vs-td> 
                    <vs-td :data="tr.offerbez">
                      {{tr.offerbez}}({{tr.offerNr}})
                    </vs-td>                                           
                    <vs-td :data="tr.order.last_change">
                      {{DateToString(tr.order.last_change,false)}}
                    </vs-td>                   
                    <vs-td :data="tr.order.state">
                      <vs-chip :color="GetCommonStateColor(tr.order.state)">
                        {{GetCommonStateText(tr.order.state)}}</vs-chip>
                    </vs-td>  
                    <vs-td :data="tr.order.created_by_str">
                      {{tr.order.created_by_str}}
                    </vs-td> 
                    <vs-td >
                      <vs-chip :color="GetsumColor(tr.order)" >{{GetOrderSumTxt(tr.order)}} <h4>{{GetSumBez(tr.order)}}</h4></vs-chip>
                    </vs-td>  
                    <vs-td >
                      {{tr.ctrInvoices}} / {{tr.ctrInvoiceBez}}
                    </vs-td>                                                                                                                                  
                    <vs-td>
                      <vs-button  @click="NavigateTo(tr.order.id)"  size="small" color="primary" type="filled" icon="open_in_new"></vs-button>
                    </vs-td>                    
                                                                                                                                                                                              
                  </vs-tr>
                </template>
              </vs-table>
            </div>           
        </vs-card>
      </vs-col>
  </vs-row>

</div>
</template>

<script>
import enumHelper from '../helper/enumHelper';
import {documentType} from "../helper/enumHelper";
import codeHelper from '../helper/staticCodeHelper';
import helperMethods from '../helper/staticFuncHelper';

export default {
  name: "OrderOverview",
  components: {
  },
    data: function (){
      return {
      orders:[],
      docType:documentType.ORDER,
      };
  },
  mounted () {
      this.LoadData();
    },
  computed: 
  { 
    getTableData() {
      var data = [];
      if(this.$store.state.accounting.orderOverview.data != null)
      {
        data = this.$store.state.accounting.orderOverview.data
      }
      return data;
    },
    loadingTableData () {
      var loading = false;
      if(typeof(this.$store.state.accounting.orderOverview.status) != 'undefined')
        loading = this.$store.state.accounting.orderOverview.status.loading;
      return loading;
    }
  },
  watch: {
      getTableData(value) {
          this.orders = value;
      },
      loadingTableData(value)
      {
        if(value)
        {
          this.$store.dispatch('alertqueue/showLoader', {'id':'#orders_table'});
        }
        else
        {
          this.$store.dispatch('alertqueue/hideLoader', {'id':'#orders_table'});
        }
      },
    },
  methods: { 
      ...enumHelper, 
      ...codeHelper,
      ...helperMethods,  
      LoadData: function ()
      {
          this.$store.dispatch('accounting/getOrderOverview', { root: true },{ root: true }); 
      },   
     GetOrderSumTxt(order)
    {
      var txt = "";
      if(this.IsNotNull(order))
      {
        if(order.orderSumBez == order.orderSum)
        {
          txt = this.GetCurrencyString(order.orderSum,order.language_fk,order.currency_kuerzel);
        }
        else
        {
          txt = this.GetCurrencyString(order.orderSum,order.language_fk,order.currency_kuerzel) + " / "+
          this.GetCurrencyString(order.orderSumVerr,order.language_fk,order.currency_kuerzel)+ " ";
        }
        
      }
      return txt;
    },
    GetsumColor(order){
      let color = "";
      if(this.IsNotNull(order))
      {
        if(order.orderSum > 0)
        {
          if(order.orderSumBez != order.orderSum )
          {
            if(order.orderSumBez > 0)
            {
              color = "orange";
            }
            else
            {
              color = "gray";
            }
          }
          else
            {
              color = "green";
            }
        }
      }
      return color;
    },
    GetSumBez(order)
    {
      var txt = "";
      if(this.IsNotNull(order))
      {
        if(order.orderSumBez != order.orderSum )
        {
          txt = " / " + this.GetCurrencyString(order.orderSumBez,order.language_fk,order.currency_kuerzel);
        }
      }
      return txt;
    },
    GetCurrencyString(amount,language_fk,currency_kuerzel){
        return this.DecimalToString(amount,currency_kuerzel,this.GetLocalFormatOfLanguage(language_fk));
      },  
    NavigateTo(id)
    {
      this.$router.push({ name: 'Order', params: { order_fk: id } });
    }   
  }
};

</script>